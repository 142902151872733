var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: "内容" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return undefined
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [_c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }