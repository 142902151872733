var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemTemplateNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "color-blue font-weight-bold cur-pot",
                    on: {
                      click: function($event) {
                        return _vm.handleGoToTemplateList(row.title)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.title))]
                )
              ])
            }
          },
          {
            key: "itemTemplateTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    class: _vm.messageTemplateContentClassType[row.informType]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedMessageTemplateContentTypeMapText(
                          row.informType
                        )
                      )
                    )
                  ]
                ),
                _c("div", { staticClass: "mt-10" }, [
                  row.informType === 1
                    ? _c(
                        "div",
                        {
                          staticClass: "cur-pot color-blue",
                          on: {
                            click: function($event) {
                              return _vm.handleShowTextContent(row.content)
                            }
                          }
                        },
                        [_vm._v("富文本内容")]
                      )
                    : _vm._e(),
                  row.informType === 2
                    ? _c(
                        "div",
                        {
                          staticClass: "w-100-w flex-center-center",
                          on: {
                            click: function($event) {
                              return _vm.handleShowBigImg(row.pictureUrl)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "w-50 text-center",
                            attrs: { src: row.pictureUrl, alt: "" }
                          })
                        ]
                      )
                    : _vm._e(),
                  row.informType === 3
                    ? _c("div", { staticClass: "cur-pot color-blue" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: row.linkUrl, target: "_blank" },
                            on: {
                              click: function($event) {
                                return _vm.handleGoToLinkUrl(row.linkUrl)
                              }
                            }
                          },
                          [_vm._v("链接内容")]
                        )
                      ])
                    : _vm._e()
                ])
              ])
            }
          },
          {
            key: "itemTemplatePushUserSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.pushObject < 4
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.messageTemplatePushUserClassType[row.pushObject]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedMessageTemplatePushUserListMapText(
                              row.pushObject
                            )
                          )
                        )
                      ]
                    )
                  : _c("div", [
                      _vm._v("【"),
                      _c("span", { staticClass: "color-green" }, [
                        _vm._v(_vm._s(row.userId))
                      ]),
                      _vm._v("】")
                    ])
              ])
            }
          },
          {
            key: "itemSContentSimpleSlot",
            fn: function(row) {
              return _c("div", {}, [_c("div", [_vm._v(_vm._s(row.intro))])])
            }
          },
          {
            key: "itemCreateOrUpdateAboutSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(" 创建时间："),
                  _c("span", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.createTime))
                  ])
                ]),
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(" 操作人ID："),
                  _c("span", { staticClass: "color-blue" }, [
                    _vm._v(_vm._s(row.adminId))
                  ])
                ]),
                _c("div", { staticClass: "color-gray" }, [
                  _vm._v(" 更新时间："),
                  _c("span", { staticClass: "color-black" }, [
                    _vm._v(_vm._s(row.updateTime))
                  ])
                ])
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mt-10",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleEditorMessagePushPopup(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("TextContentPopup", { ref: "textContentPopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }