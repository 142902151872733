import {objArrMapObj} from "@/untils/otherEvent"

/** 店铺标记 */
export const shopTagDescList = [
    {value: '历史售假', id: 0},
    {id: 'auto', value: '输入其他自定义原因', icon: 'edit'}
]

// "orderNo": "2024011714520001",
//     "recoFlag": 5,
//     "settlementTime": "2024-01-17 14:52:57",
//     "orderPrice": 12.00
/** 店铺金额明细table */
export const SHOP_PRICE_LINE_TABLE_COLUMNS = [
    { title: "订单编号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" } },
    { title: "金额", align: "center", dataIndex: 'orderPrice' },
    { title: "提现状态", align: "center", scopedSlots: { customRender: "itemRecFlagSlot" }, width: 100 },
    { title: "确认收货时间", align: "center", dataIndex: 'settlementTime' },
]

/** 对应颜色 */
export const recFlagClassType = {
    1: {
        classType: 'color-gray',
        text: '待提现'
    },
    5: {
        classType: 'color-red',
        text: '处理中'
    }
}

/** 消息通知模版类型 */
export const messageTemplateContentTypeList = [
    { name: '富文本', value: 1 },
    { name: '图片', value: 2 },
    { name: '链接', value: 3 }
]
/** 消息通知模版类型样式类 */
export const messageTemplateContentClassType = {
    1: 'color-black',
    2: 'color-orange',
    3: 'color-blue'
}

export const messageTemplateContentTypeMapText = (value) => {
    return objArrMapObj('value', 'name', messageTemplateContentTypeList)[value]
}

/** 消息通知模版推送对象 */
export const messageTemplatePushUserList = [
    { name: '卖家', value: 1 },
    { name: '买家', value: 2 },
    { name: '全部', value: 3 },
    { name: '自定义', value: 4 }
]
/** 消息通知模版推送对象样式 */
export const messageTemplatePushUserClassType = {
    1: 'color-orange',
    2: 'color-green',
    3: 'color-blue',
    4: 'color-black'
}
export const messageTemplatePushUserListMapText = (value) => {
    return objArrMapObj('value', 'name', messageTemplatePushUserList)[value]
}

/** 消息通知模版是否是弹窗 */
export const messageTemplateIsPopupTypeList = [
    { name: '是弹窗', value: 1 },
    { name: '非弹窗', value: 0 },
]
/** 消息通知模版是否是弹窗样式类 */
export const messageTemplateIsPopupTypeClassType = {
    1: 'color-green',
    2: 'color-red'
}
export const messageTemplateIsPopupTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', messageTemplateIsPopupTypeList)[value]
}

/** 消息通知模版是否已经推送 */
export const messageTemplatePushStatusTypeList = [
    { name: '已发布', value: 1 },
    { name: '草稿', value: 0 },
]
/** 消息通知模版是否已经推送样式类 */
export const messageTemplatePushStatusTypeClassType = {
    1: 'color-green',
    0: 'color-red',
}
export const messageTemplatePushStatusTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', messageTemplatePushStatusTypeList)[value]
}