<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  模版标题    -->
      <div slot="itemTemplateNameSlot" slot-scope="row">
        <div class="color-blue font-weight-bold cur-pot" @click="handleGoToTemplateList(row.title)">{{ row.title }}</div>
      </div>
<!--  模版类型    -->
      <div slot="itemTemplateTypeSlot" slot-scope="row">
        <div :class="messageTemplateContentClassType[row.informType]">{{ computedMessageTemplateContentTypeMapText(row.informType) }}</div>
        <div class="mt-10">
          <div v-if="row.informType === 1" class="cur-pot color-blue" @click="handleShowTextContent(row.content)">富文本内容</div>
          <div v-if="row.informType === 2" class="w-100-w flex-center-center" @click="handleShowBigImg(row.pictureUrl)">
            <img class="w-50 text-center" :src="row.pictureUrl" alt="">
          </div>
          <div v-if="row.informType === 3" class="cur-pot color-blue">
            <a :href="row.linkUrl" target="_blank" @click="handleGoToLinkUrl(row.linkUrl)">链接内容</a>
          </div>
        </div>
      </div>
<!--  推送对象    -->
      <div slot="itemTemplatePushUserSlot" slot-scope="row">
        <div v-if="row.pushObject < 4" :class="messageTemplatePushUserClassType[row.pushObject]">{{ computedMessageTemplatePushUserListMapText(row.pushObject) }}</div>
        <div v-else>【<span class="color-green">{{ row.userId }}</span>】</div>
      </div>
<!--  简介    -->
      <div slot="itemSContentSimpleSlot" slot-scope="row">
        <div>{{ row.intro }}</div>
      </div>
<!--   操作时间   -->
      <div slot="itemCreateOrUpdateAboutSlot" slot-scope="row">
        <div class="color-gray">
          创建时间：<span class="color-black">{{ row.createTime }}</span>
        </div>
        <div class="color-gray">
          操作人ID：<span class="color-blue">{{ row.adminId }}</span>
        </div>
        <div class="color-gray">
          更新时间：<span class="color-black">{{ row.updateTime }}</span>
        </div>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            class="mt-10"
            size="small"
            type="primary"
            @click="handleEditorMessagePushPopup(row)"
        >编辑</a-button>
      </div>
    </a-table>
<!--  内容  -->
    <TextContentPopup ref="textContentPopupEl"/>
  </div>
</template>
<script>

import {messagePushTableColumns} from "@/views/cmsPage/shopManage/messagePushList/_data"
import {
  messageTemplateContentClassType,
  messageTemplateContentTypeMapText,
  messageTemplatePushUserClassType,
  messageTemplatePushUserListMapText
} from "@/views/cmsPage/shopManage/_data"
import TextContentPopup from "@/views/cmsPage/shopManage/messageTemplateList/_components/TextContentPopup/index.vue"

export default {
  props: ['pagination', 'list'],
  components: {TextContentPopup},
  data() {
    return {
      tableList: messagePushTableColumns,
      messageTemplatePushUserClassType,
      messageTemplateContentClassType
    }
  },
  computed: {
    computedMessageTemplatePushUserListMapText() {
      return (value) => messageTemplatePushUserListMapText(value)
    },
    computedMessageTemplateContentTypeMapText() {
      return (value) => messageTemplateContentTypeMapText(value)
    },
  },
  methods: {
    /** 编辑推送公告信息 */
    handleEditorMessagePushPopup(row) {

    },
    /** 展示富文本内容 */
    handleShowTextContent(content) {
      this.$refs.textContentPopupEl.show(content)
    },
    /** 去往链接页面 */
    handleGoToLinkUrl(link) {
      window.open(link)
    },
    /** 展示大图 */
    handleShowBigImg(img) {
      this.$previewImg({
        list: [{img_url: img}],
        baseImgField: "img_url",
        showMute: false,
        current: 0
      });
    },
    /** 去往模版页面 */
    handleGoToTemplateList(title) {
      const origin = location.origin
      const url = `${origin}/#/cms/usershop/templatelist?title=${title}`;
      window.open(url)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>