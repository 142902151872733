import instance from "@/axios"

/** 获取信息推送列表 */
export const getMessagePushListApi = async (params) => {
    return instance({
        url: '/dq_admin/platformPushDetail/list',
        method: 'GET',
        params: params
    })
}

/** 编辑模版 */
export const pushMessageApi = async (params) => {
    return instance({
        url: '/dq_admin/platformPushDetail/push',
        method: 'post',
        data: params
    })
}