<template>
  <a-modal
      v-model="showPopup"
      :width="800"
      title="推送信息"
      @cancel="handleCancel"
      @ok="handleConfirmEditorTemplate"
  >
    <a-form-model v-bind="layout">
      <a-form-item label="模版选择">
        <a-select
            :filter-option="untils.filterOption"
            @search="handleSearchTemplate"
            allowClear
            showSearch
            class="w-500"
            v-model="params.pushTemplateId"
            placeholder="请选择信息模版"
            @change="handleChangeSelectedTemplate"
        >
          <a-select-option
              v-for="items of messageTemplateList"
              :key="items.id"
              :value="items.id"
          >{{ items.title }}</a-select-option>
        </a-select>
        <a-button
            class="ml-10"
            type="link"
            v-if="params.pushTemplateId"
            @click="handleGoToTemplateList(params.pushTemplateId)"
        >查看模版细节></a-button>
      </a-form-item>
      <a-form-item label="用户选择" v-if="pushObject === 4">
        <a-select
            allowClear
            v-model="params.userIds"
            mode="multiple"
            :dropdownMatchSelectWidth="false"
            :filter-option="untils.filterOption"
            showSearch
            @search="handleSearchUser"
            placeholder='请输入店铺名称/昵称/userId'
        >
          <a-select-option
              v-for="item in userInfoList"
              :key="item.userId"
              :value='item.userId'>{{ item.userName + `【${item.userId}】` }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import untils from "@/untils"
import {mapState} from "vuex"
import {pushMessageApi} from "@/views/cmsPage/shopManage/messagePushList/_apis"
import {getMessageTemplateListApi} from "@/views/cmsPage/shopManage/messageTemplateList/_apis"
import {getUserInfoByUserIdOrUserNameApi} from "@/axios/apis"

export default {
  components: {},
  data() {
    return {
      untils,
      showPopup: false,
      messageTemplateList: [],
      userInfoList: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        pushTemplateId: undefined,
        userIds: []
      },
      pushObject: 1,
    };
  },
  computed: {
    ...mapState(['ip'])
  },
  methods: {
    async show() {
      await this.handleSearchTemplate()
      this.showPopup = true
    },
    /** 改变选择的模版 */
    handleChangeSelectedTemplate(id) {
      this.pushObject = this.messageTemplateList.find(item => item.id === id).pushObject
    },
    /** 前往细节 */
    handleGoToTemplateList(id) {
      const foundTitle = this.messageTemplateList.find(item => item.id === id).title
      const origin = location.origin
      const url = `${origin}/#/cms/usershop/templatelist?title=${foundTitle}`;
      window.open(url)
    },
    /** 确定 */
    async handleConfirmEditorTemplate() {
      this.$loading.show()
      const res = await pushMessageApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message)
      this.showPopup = false
      this.$emit('success')
    },
    /** 获取模版 */
    async handleSearchTemplate(str) {
      const res = await getMessageTemplateListApi({
        pageSize: 30,
        pageNum: 1,
        title: str,
        status: 1,
      })
      if (res.status !== '200') return
      this.messageTemplateList = res.data.records;
    },
    /** 获取用户列表 */
    async handleSearchUser(str) {
      const res = await getUserInfoByUserIdOrUserNameApi(str)
      if (res.status !== "200") return
      const { records } = res.data;
      this.userInfoList = records
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        pushTemplateId: undefined,
        userIds: []
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>