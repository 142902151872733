<template>
  <a-modal
      v-model="showPopup"
      :width="800"
      title="内容"
  >
    <template #footer></template>
    <div v-html="content"></div>
  </a-modal>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      content: ''
    };
  },
  methods: {
    show(data) {
    this.content = data
    this.showPopup = true
    }
  },
};
</script>

<style lang="scss" scoped>
</style>