import instance from "@/axios"

/** 获取消息模版列表 */
export const getMessageTemplateListApi = async (params) => {
    return instance({
        url: '/dq_admin/platformPushTemplate/list',
        method: 'GET',
        params: params
    })
}
/** 删除当前模版 */
export const delMessageTemplateApi = async (params) => {
    return instance({
        url: '/dq_admin/platformPushTemplate/delById',
        method: 'GET',
        params: params
    })
}

/** 编辑模版 */
export const editorMessageTemplateApi = async (apiUrl, params) => {
    return instance({
        url: apiUrl,
        method: 'post',
        data: params
    })
}